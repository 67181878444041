import { useEffect, useMemo, useRef, useState } from 'react';
import { HEADER_HEIGHT } from 'constants/common';
import { IRoomParams } from 'interfaces';

import { useStreamHeight } from './useStreamHeight';
import { useStreamsDynamicGrid } from './useStreamsDynamicGrid';
import { useWindowSize } from './useWindowSize';

export const useStreamControl = (
  rooms: IRoomParams[],
  isOpenFullScreen: boolean
) => {
  const [visibleStreams, setVisibleStreams] = useState<IRoomParams[]>([]);

  const [unloadedStreams, setUnloadedStreams] = useState<IRoomParams[]>([]);

  const { height, width } = useWindowSize();

  const gridSize = useStreamsDynamicGrid(rooms);

  const visibleStreamsCounter = useRef(0);

  const unloadedStreamsCounter = useRef(0);

  const screenHeight = useMemo(
    () => (isOpenFullScreen ? height : height - HEADER_HEIGHT),
    [isOpenFullScreen, height]
  );

  const streamHeight = useStreamHeight(rooms, screenHeight);

  const maxVisibleStreams =
    unloadedStreamsCounter.current < unloadedStreams.length
      ? unloadedStreamsCounter.current
      : unloadedStreams.length;

  const streamsInRow = Math.floor(width / (width / gridSize));

  const streamsInColumn = Math.floor(screenHeight / streamHeight);

  const totalVisibleStreams = streamsInRow * streamsInColumn;

  const handleVisibleStreamsChange = () => {
    visibleStreamsCounter.current =
      visibleStreamsCounter.current + maxVisibleStreams;

    const totalVisibleStreams = rooms.slice(0, visibleStreamsCounter.current);
    const totalUnloadedStreams = rooms.slice(visibleStreamsCounter.current);

    setVisibleStreams(totalVisibleStreams);

    setUnloadedStreams(totalUnloadedStreams);
  };

  useEffect(() => {
    if (Number.isFinite(totalVisibleStreams)) {
      visibleStreamsCounter.current = totalVisibleStreams;
      unloadedStreamsCounter.current = totalVisibleStreams;
    }
  }, [totalVisibleStreams]);

  useEffect(() => {
    const totalVisibleRooms = rooms.slice(0, visibleStreamsCounter.current);
    const totalUnloadedRooms = rooms.slice(visibleStreamsCounter.current);

    setVisibleStreams(totalVisibleRooms);
    setUnloadedStreams(totalUnloadedRooms);
  }, [visibleStreamsCounter, rooms]);

  return {
    visibleStreams,
    unloadedStreams,
    maxVisibleStreams,
    handleVisibleStreamsChange,
  };
};
