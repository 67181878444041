import { IRoomParams } from 'interfaces';

import { useBreakPoint } from './useBreakPoint';

export const useStreamsDynamicGrid = (rooms: IRoomParams[]) => {
  const breakPoint = useBreakPoint();

  const gridSize =
    ((breakPoint === 'mobile' ||
      breakPoint === 'tablet' ||
      rooms.length === 1) &&
      1) ||
    (rooms.length >= 2 && rooms.length <= 4 && 2) ||
    (((rooms.length >= 5 && rooms.length <= 9) ||
      breakPoint === 'tablet-landscape') &&
      3) ||
    (rooms.length >= 10 && rooms.length <= 16 && 4) ||
    5;

  return gridSize;
};
